import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-aube-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"]
})
export class ConfirmComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmComponent>) { }

  public confirmMessage: string;
  public title: string = "Confirm";
  public confirmButtonText: string = "";
  public IsDelete: boolean = false;
}
