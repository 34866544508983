<h1 mat-dialog-title>
    {{title}}
</h1>
<div mat-dialog-content class="m-0 pb-0 p-16">{{confirmMessage}}</div>
<div mat-dialog-actions class="m-0 p-16 wrap-action-btn">
    <button mat-stroked-button
            mat-button
            class="cancel-btn mr-8"
            (click)="dialogRef.close(false)"
            aria-label="Cancel">
        {{'cancel' | translate}}
    </button>

    <button mat-flat-button
            mat-button color="warn"
            class="delete-btn mr-8"
            [class]="IsDelete ? 'delete-button' : ''"
            (click)="dialogRef.close(true)"
            aria-label="Confirm">
        {{confirmButtonText !== '' ? confirmButtonText : ('confirm' | translate)}}
    </button>
</div>
