import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from "@auth0/auth0-angular";
import { Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AlertService } from "diva-lib";
import { ConflowSettings } from "./settings/conflow-settings";
import { ErrorCodes } from "../core/auth/errorcodes";
import { GlobalService } from "./services/global.service";
import { HttpStatus } from "src/app/shared/const/const";

@Injectable({
  providedIn: "root",
})
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private alertService: AlertService,
    private globalService: GlobalService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has(ConflowSettings.noAuthValidateHeaderParam)) {
      return this.auth.getAccessTokenSilently().pipe(
        mergeMap((token) => {
          let space = this.globalService.currentSpaceId;

          if (!space) {
            space = "";
          }
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}`, Space: space },
          });

          return next.handle(tokenReq);
        }),
        catchError((error) => {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            this.forceLogout();
          } else if (error.error === "login_required") {
            this.forceLogout();
          } else {
            // if (!tokenRe.headers.has(ConflowUtils.headerToSkipHttpInterceptorErrorPopup)) {
            this.alertService.showWarningSnakBar("Error Occurred!");
            // }
          }
          return throwError(() => error);
        })
      );
    }
    return next.handle(req);
  }

  private forceLogout() {
    this.auth.logout({
      logoutParams: {
        returnTo: `${document.location.origin}/index?errorcode=${ErrorCodes.SESSIONEXPIRED}`
      }
    });
  }
}
