<h1 mat-dialog-title>
    {{'confirm'| translate}}
    <button class="mat-icon-button" [mat-dialog-close]><mat-icon matSuffix class="material-icons-outlined">close</mat-icon></button>
</h1>
<div mat-dialog-content>
    <div class="mb10">{{confirmMessage}}</div>
    <mat-form-field class="w100 mb10">
        <mat-label>{{ confirmSubMessage }}</mat-label>
        <input matInput placeholder="{{confirmSubMessage}}" name="enteredKeyword" [(ngModel)]="enteredKeyword">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button class="cancel-btn" [mat-dialog-close] (click)="dialogRef.close(false)">{{'cancel'| translate}}</button>
    <button mat-flat-button class="delete-btn" [mat-dialog-close] color="warn" (click)="onConfirm()">{{'Delete'| translate}}</button>
</div>