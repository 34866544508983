import { Component, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "diva-lib";

@Component({
  selector: "app-confirm-dialog-assured",
  templateUrl: "./confirm-dialog-assured.component.html",
  styleUrls: ["./confirm-dialog-assured.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogAssuredComponent {
  public confirmMessage: string;
  public confirmSubMessage: string;
  public mismatchAlertMessage: string;
  public keyword: string;
  public caseSensitive: boolean = false;

  public enteredKeyword: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogAssuredComponent>,
    private alertService: AlertService) { }

  onEnteredKeywordChange(value: string) {
    this.enteredKeyword = value;
  }

  onConfirm() {
    const isMatched = this.caseSensitive ? this.keyword === this.enteredKeyword
      : this.keyword.toUpperCase() === this.enteredKeyword.toUpperCase();

    isMatched ? this.dialogRef.close(true) : this.alertService.showWarningSnakBar(this.mismatchAlertMessage);
  }
}
